import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/cryptowander/Maker/test/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`MIP1c4: Subproposal for changing the Problem Space`}</h1>
    <h2>{`Preamble`}</h2>
    <pre><code parentName="pre" {...{}}>{`MIP1c4-SP#: # 
Titlle of problem Space Item to be Added or Deleted:
Author(s):
Contributors:
Status:
Date of Submission: <yyyy-mm-dd>
Date of Ratification: <yyyy-mm-dd>
`}</code></pre>
    <h2>{`Specification`}</h2>
    <h3>{`Motivation`}</h3>
    <ul>
      <li parentName="ul">{`Explanation behind the addition or deletion to the Problem Space list. `}</li>
    </ul>
    <h3>{`Relevant Information`}</h3>
    <ul>
      <li parentName="ul">{`Links to evidence further backing the motivation.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      